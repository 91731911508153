import { VariantProps, cva } from 'class-variance-authority';
import { forwardRef } from 'react';
import { cn } from '~/lib/utils';

const typographyVariants = cva('', {
  variants: {
    variant: {
      headline1:
        'font-montserrat font-bold xl:text-[54px] xl:leading-[54px] lg:text-[49.6px] lg:leading-[49.6px] md:text-[45.2px] md:leading-[45.2px] sm:text-[40.8px] sm:leading-[40.8px] text-[32px] leading-[32px]',
      headline2:
        'font-montserrat font-bold xl:text-[40px] lg:text[38px] md:text[36px] text-[34px] leading-[40px]',
      headline3:
        'font-montserrat font-bold xl:text-[34px] lg:text[32px] md:text[30px] text-[28px] leading-[34px]',
      headline4:
        'font-montserrat font-bold xl:text-[28px] lg:text[26px] md:text[24px] text-[22px] leading-[28px]',
      headline5:
        'font-montserrat font-bold xl:text-[24px] lg:text[22px] text-[20px] leading-[24px]',
      headline6:
        'font-montserrat font-bold xl:text-[20px] text-[18px] leading-[24px]',
      headline6Lighter:
        'font-montserrat font-medium xl:text-[20px] text-[18px] leading-[24px]',
      body1:
        'font-manrope font-normal text-[16px] leading-[24px]',
      body2:
        'font-manrope font-normal text-[14px] leading-[20px]',
      button:
        'font-manrope font-bold text-white text-[16px] leading-[24px]',
      link: 
        'font-manrope font-normal text-[14px] leading-[10px]',
      caption:
        'font-manrope font-normal text-[11px] leading-[18px]',
      label:
        'font-manrope font-normal text-[14px] leading-[26px]',
      overline:
        'font-manrope font-normal text-[10px] leading-[12px]',
      italic: 
        'font-manrope font-normal text-[12px] leading-[20px] italic',
    },
  },
  defaultVariants: {
    variant: 'body1',
  },
});

export interface TypographyProps
  extends React.HTMLProps<HTMLElement>,
  VariantProps<typeof typographyVariants> {
  className?: string;
  htmlFor?: string;
  type?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'label' | 'p' | 'label';
}

const Typography = forwardRef<HTMLElement, TypographyProps>(({ className, variant, children, type, ...props }, ref) => {
  const variants = typographyVariants({ variant, className });
  const component = {
    headline1: 'h1',
    headline2: 'h2',
    headline3: 'h3',
    headline4: 'h4',
    headline5: 'h5',
    headline6: 'h6',
    headline6Lighter: 'h6',
    body1: 'p',
    body2: 'p',
    button: 'p',
    link: 'p',
    caption: 'label',
    label: 'label',
    overline: 'label',
    italic: 'p',
  };
  const Component = type || component[variant || 'body1'] as React.ReactNode;
  return (
    <Component {...props} ref={ref} className={cn(variants)}>
      {children}
    </Component>
  )
},
);

export { Typography, typographyVariants as TypographyVariants };
