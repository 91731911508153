import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import fetch from 'node-fetch';
import { ProductModel } from "~/models";

export const phoneCountryCode = '+1';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export async function responseFromCdnUrl(url: string): Promise<Response> {
  const filename = url.split('/').reverse()[0];

  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`Error response from ${url}: ${response.statusText}`);
    }
    const contentType = response.headers.get('Content-Type') || 'application/octet-stream';
    const body = response.body;

    return new Response(body as any, {
      status: 200,
      headers: {
        'Content-Type': contentType,
        'Cache-Control': `no-cache`,
        'Content-Disposition': `inline; filename="${filename}"`
      },
    });
  } catch (error) {
    console.error(`Failed to fetch ${url}`, error);
    return new Response(`Error fetching ${url}`, { status: 500 });
  }
}

export function getShopifyProductId(productId?: string, productVariant?: string | undefined) {
  if (!productId && !productVariant) {
    return "null";
  }
  const actualProductId = productId ? getStringLastPart(productId) : "";
  const countryCode = 'US';
  const productVariantId = productVariant ? getStringLastPart(productVariant) : "";
  return `shopify_${countryCode}_${actualProductId}_${productVariantId}`;
}

export function getStringLastPart(str:string, separator?: string) {
  return separator ? str.substring(str.lastIndexOf(separator) + 1) : str.substring(str.lastIndexOf('/') + 1);
}

export function buildProductDataForTracking(products: Array<ProductModel>, category: string | undefined) {
  // Initialize an empty array to store product data
  const productsData: {
    item_id: string | undefined;
    item_name: string | undefined;
    currency: string;
    item_brand: string;
    item_category: string | undefined;
    price: number;
    quantity: number;
    discount: string;
  }[] = [];

  // Iterate through your list of products and add each product's data to the array
  products.forEach((product) => {
    const selectedVariant = product?.variants?.edges ? product?.variants?.edges[0].node : '';
    const variantPrice = selectedVariant ? parseFloat((selectedVariant?.priceV2?.amount || '0').toString()) : 0;
    const variantId = selectedVariant ? selectedVariant.id : '';
    const productId = product ? product?.id : '';
    productsData.push({
      item_id: getShopifyProductId(productId, variantId), // Use a unique identifier like SKU or product ID
      item_name: product.title, // Product name
      currency: 'USD', // Currency in ISO 4217 format e.g. USD EUR GBP etc
      item_brand: product.vendor || 'Unknown', // Brand or vendor of the item
      item_category: category, // Category product pertains to
      price: variantPrice, // Price in float format, must not have commas or currency signs. Must show list price before discount amount applied
      quantity: 1, // Quantity of times the product appears on the page (usually 1)
      discount: '', // If on sale, list the discount amount in float format and must not have commas or currency signs
    });
  });
  return productsData;
}